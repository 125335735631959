import { Box, Typography, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';


const LandingPage = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
      <Typography variant="h4" align="center" gutterBottom sx={{ marginBottom: '2rem' }}>
        We're cooking up something new!
      </Typography>
      <img src="mobileBanner.png" alt="Company Logo" style={{ width: '400px', marginBottom: '2rem' }} />
      <Typography variant="body1" align="center" sx={{ px: '2rem' }}>
        Thank you for trusting Kora to make everyday mealtimes easy, affordable, and homemade.
      </Typography>
      <Typography variant="body1" align="center" sx={{ px: '2rem' }}>
        We are reimagining ways to improve your Kora experience. Until then we will miss cooking for you this summer.
      </Typography>
      <Typography variant="body1" align="center">
        Stay tuned as we make something new.
      </Typography>
      <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
        <IconButton
          component="a"
          href="https://www.facebook.com/koracommunity"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Facebook"
          size="small"
        >
          <FacebookIcon />
        </IconButton>
        <IconButton
          component="a"
          href="https://www.instagram.com/koracommunity"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Instagram"
          size="small"
        >
          <InstagramIcon />
        </IconButton>
        <IconButton
          component="a"
          href="https://twitter.com/koracommunity"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Twitter"
          size="small"
        >
          <TwitterIcon />
        </IconButton>
      </Box>
      <Typography variant="body2" color="text.secondary" align="center" mt={2}>
        © {new Date().getFullYear()} Kora Life Inc.
      </Typography>
    </Box>
  );
};

export default LandingPage;
